import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Prize-linked Savings (PLS) was a hot topic at the Center for Economic Development’s Asset Building Conference where thousands of asset building advocates convened in DC to identify clear ways of translating ideas into action. Toward that end, PayPerks was asked to address the crowd about how its rewards platform can be leveraged by social impact organizations and financial institutions to promote financial inclusion and education. PayPerks’ founder Arlyn Davich spoke with Joanna Smith-Ramani from the D2D Fund (Doorways to Dreams Fund) and Robin McKinney from Maryland CASH about the Future of Prize-Linked Savings and how advocates can bring PLS to their state through tools like PayPerks.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eiseverywhere.com/ehome/2012ALC/agenda/future_of_prize_linked_savings"
      }}>{`Future of Prize-Linked Savings: New Strategies for Practitioners and Advocates`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      